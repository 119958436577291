<template>
    <el-col :span="24" style="background-color: #ffffff">
        <el-col :span="24">
            <div class="container">
                <div class="handle-box">
                    <el-select
                        v-model="schoolIndex"
                        placeholder="学校"
                        style="float: left; margin-right: 10px"
                        :disabled="searchSchoolState"
                        @change="changeSchool"
                    >
                        <el-option v-for="item in schoolIndexList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                    <el-select v-model="situIndex" placeholder="外出策略" style="float: left; margin-right: 10px" @change="changeSitu">
                        <el-option v-for="item in situList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                    <div>当前执行外出策略：{{ situText }}</div>
                </div>
                <div class="handle-box">
                    <el-input type="textarea" :rows="6" :value="situCon" placeholder="外出策略详细说明" class="te"> </el-input>
                </div>
                <div class="handle-box">
                    <div style="display: flex; line-height: 26px">
                        <div>学生在</div>
                        <input v-model="freeDay" placeholder="天数" style="width: 60px; padding: 4px; text-align: center" />
                        <div>天内申请前</div>
                        <input v-model="freeNum" placeholder="次数" style="width: 60px; padding: 4px; text-align: center" />
                        <div>次，免审核 (仅作用于A-3，A-4，B-4策略)</div>
                    </div>
                </div>
                <div class="handle-box">
                    <div style="display: flex; line-height: 26px">
                        <div>学生申请不超过</div>
                        <input v-model="freeDayLong" placeholder="合计天数" style="width: 60px; padding: 4px; text-align: center" />
                        <div>天内免审核 (仅作用于A-2，A-4，B-4策略)</div>
                    </div>
                </div>
                <div class="handle-box">
                    <div style="display: flex; line-height: 26px">
                        <input v-model="province" placeholder="省份" style="width: 60px; padding: 4px; text-align: center" />
                        <div>（省份）</div>
                        <input v-model="city" placeholder="城市" style="width: 60px; padding: 4px; text-align: center" />
                        <div>（城市）</div>
                        <input v-model="district" placeholder="区" style="width: 60px; padding: 4px; text-align: center" />
                        <div>（区）(仅作用于B-1，B-3，B-4策略)</div>
                    </div>
                </div>
                <div style="margin-bottom: 10px; color: red">学生外出事由选项（注：一行为一个选项）</div>
                <div style="margin-bottom: 10px; color: red">选项前加*号则学生选择该选项时需填外出理由（例：*请假回家）</div>
                <div class="handle-box" style="width: 30vw">
                    <el-input type="textarea" :rows="6" v-model="subject" placeholder="学生外出事由选项（注：一行一个）" class="te">
                    </el-input>
                </div>
                <div class="handle-box">
                    <el-button type="primary" @click="saveBut">保存</el-button>
                </div>
            </div>
        </el-col>
    </el-col>
</template>

<script>
import { fetchData } from '../../api/index';
export default {
    name: 'basetable',
    data() {
        return {
            query: {
                pageIndex: 1,
                pageSize: 10
            },
            tableData: [],
            pageTotal: 0,
            searchSchoolState: false,
            schoolIndex: '',
            situList: '',
            situIndex: '',
            situText: '',
            situCon: '',
            freeDay: '',
            freeNum: '',
            freeDayLong: '',
            subject: '',
            district: '',
            city: '',
            province: ''
        };
    },
    activated() {},
    created() {
        var that = this;
        var schoolList = [];
        var userType = JSON.parse(window.sessionStorage.getItem('userType'));
        console.log(userType);
        for (var i = 0; i < userType.length; i++) {
            var userTypeObj = {
                value: userType[i].schoolId,
                label: userType[i].schoolName
            };
            schoolList.push(userTypeObj);
        }
        this.schoolIndexList = schoolList;
        this.schoolIndex = userType[0].schoolId;
        if (this.schoolIndexList.length <= 1) {
            this.searchSchoolState = true;
        }

        var query = {
            data: {
                data: 1
            },
            url: '/get_situLevelList.cls.php'
        };
        fetchData(query).then((res) => {
            var situList = [];
            for (var i = 0; i < res.data.length; i++) {
                var userTypeObj = {
                    value: res.data[i].id,
                    label: res.data[i].name,
                    desc: res.data[i].desc
                };
                situList.push(userTypeObj);
                if (that.situId == res.data[i].id) {
                    console.log(i);
                    that.situText = res.data[i].name;
                }
            }
            this.situList = situList;
            this.getSituId();
        });
    },
    methods: {
        getSituId() {
            var that = this;
            var query = {
                data: {
                    schoolId: this.schoolIndex
                },
                url: '/get_schoolSituLevel.cls.php'
            };
            fetchData(query).then((res) => {
                for (var i = 0; i < that.situList.length; i++) {
                    if (res.situLevel == that.situList[i].value) {
                        that.situText = that.situList[i].label;
                        that.situIndex = that.situList[i].value;
                        that.situCon = that.situList[i].desc;
                    }
                    that.freeDay = res.freeDay;
                    that.freeNum = res.freeNum;
                    that.freeDayLong = res.freeDayLong;
                    that.province = res.province;
                    that.city = res.city;
                    that.district = res.district;
                    that.subject = res.subject;
                }
            });
        },
        changeSitu() {
            var that = this;
            console.log(this.situIndex);
            for (var i = 0; i < that.situList.length; i++) {
                if (this.situIndex == that.situList[i].value) {
                    that.situCon = that.situList[i].desc;
                }
            }
        },
        // 监听学校选择器
        changeSchool() {
            this.getSituId();
        },

        // 二级院校上传
        saveBut() {
            var that = this;
            if (that.freeDay == undefined || that.freeDay == '') {
                this.$message.error('天数不能为空');
            } else if (that.freeNum == undefined || that.freeNum == '') {
                this.$message.error('次数不能为空');
            } else if (that.freeDayLong == undefined || that.freeDayLong == '') {
                this.$message.error('合计天数不能为空');
            } else if (that.province == undefined || that.province == '') {
                this.$message.error('省份不能为空');
            } else if (that.city == undefined || that.city == '') {
                this.$message.error('城市不能为空');
            } else if (that.district == undefined || that.district == '') {
                this.$message.error('区不能为空');
            } else {
                var query = {
                    data: {
                        schoolId: that.schoolIndex,
                        id: that.situIndex,
                        freeDay: that.freeDay,
                        freeNum: that.freeNum,
                        freeDayLong: that.freeDayLong,
                        province: that.province,
                        city: that.city,
                        district: that.district,
                        subject: that.subject
                    },
                    url: '/update_schoolSituInfo.cls.php'
                };
                fetchData(query).then((res) => {
                    if (res.code == 0) {
                        this.$message.success(res.msg);
                        this.getSituId();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },

        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val;
            this.$set(this.query, 'pageIndex', val);
            this.getgoodList();
        },
        inputChange() {
            this.$forceUpdate();
        }
    }
};
</script>

<style scoped>
.el-cascader {
    width: 90%;
    padding-right: 10px;
}
.demonstration {
    color: #948c8c;
}
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 200px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
.block2 {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}
.te >>> textarea {
    color: #000;
}
</style>
